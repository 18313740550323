/** Here rests all the base query keys for each React Query */
export const queries = {
    getAccountStatements: 'getAccountStatements',
    getAccountStatmentMonths: 'getAccountStatementMonths',
    getAlgoliaQuery: 'getAlgoliaQuery',
    getAssetDetails: 'getAssetDetails',
    getBankWireUsInformation: 'getBankWireUsInformation',
    getBankWireInternationalInformation: 'getBankWireInternationalInformation',
    getCollectionById: 'getCollectionById',
    getCollectionByIds: 'getCollectionByIds',
    getCollectionAssets: 'getCollectionAssets',
    getAuthenticatedCollectionAssets: 'getAuthenticatedCollectionAssets',
    getHistoricalPricing: 'getHistoricalPricing',
    getProductPrice: 'getProductPrice',
    getLastTradePrice: 'getLastTradePrice',
    getLiquidationWines: 'getLiquidationWines',
    getManagedAccountFees: 'getManagedAccountFees',
    getManagedPendingCharges: 'getManagedPendingCharges',
    getManagedPortfolio: 'getManagedPortfolio',
    getManagedPortfolioInfo: 'getManagedPortfolioInfo',
    getManagedUserProfile: 'getManagedUserProfile',
    getMarketOrders: 'getMarketOrders',
    getMarketPrice: 'getMarketPrice',
    getPendingTransfers: 'getPendingTransfers',
    getPlaidLinkToken: 'getPlaidLinkToken',
    getReferralProfile: 'getReferralProfile',
    getStrapiCollectionById: 'getStrapiCollectionById',
    getStrapiProducer: 'getStrapiProducer',
    getStripeSubscriptionManagementUrl: 'getStripeSubscriptionManagementUrl',
    getTradingPendingCharges: 'getTradingPendingCharges',
    getTradingPortfolio: 'getTradingPortfolio',
    getTradingPortfolioBottles: 'getTradingPortfolioBottles',
    getTradingPortfolioBottlesInfo: 'getTradingPortfolioBottlesInfo',
    getUserAssetCSV: 'getUserAssetCSV',
    getUserPortfolio: 'getUserPortfolio',
    getUserPortfolioOrders: 'getUserPortfolioOrders',
    getUserProfile: 'getUserProfile',
    getAllManagedPortfolios: 'getAllManagedPortfolios',
    getUserInformation: 'getUserInformation',
    getUserAutoPayStatus: 'getUserAutoPayStatus',
    getUserWithdrawalEligibility: 'getUserWithdrawalEligibility',
    getUserAllocationPreferences: 'getUserAllocationPreferences',
    getUserLinkedBanks: 'getUserLinkedBanks',
    getManagedPortfolioAssets: 'getManagedPortfolioAssets',
    getUserWines: 'getUserWines',
    getLwin7Descriptions: 'getLwin7Descriptions',
    getUserReferralInfo: 'getUserReferralInfo',
    getSavedPaymentMethods: 'getSavedPaymentMethods',
    getTradingOrders: 'getTradingOrders',
    getTradingTransactions: 'getTradingTransactions',
    getTradingOrder: 'getTradingOrder',
    getIsNewUser: 'getIsNewUser',
    getUserCashAwardReferral: 'getUserCashAwardReferral',
    getPortfolioBankWireInformation: 'getPortfolioBankWireInformation',
    getOnboardingState: 'getOnboardingState',
    getUserPreferences: 'getUserPreferences',
    finalizeDeposit: 'finalizeDeposit',
    getPendingOfferListings: 'getPendingOfferListings',
    getOfferListings: 'getOfferListings',
    getWhiskeyPortfolio: 'getWhiskeyPortfolio',
    getWhiskeyPendingCharges: 'getWhiskeyPendingCharges',
    getWhiskeyPortfolioInfo: 'getWhiskeyPortfolioInfo',
    getWhiskeyUserProfile: 'getWhiskeyUserProfile',
    queryCMSSupport: 'queryCMSSupport'
};
