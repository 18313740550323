import { OrderStatus } from '~/models/market/OrderStatus';
import { PortfolioTypes } from '~/models/PortfolioType';

import { Currencies } from './currencies';

export enum AnalyticsEventNames {
    AccountSettings = 'account_settings',
    AccountExistsError = 'account_exists_error',
    ActiveToggle = 'active_toggle',
    AddFunds = 'add_funds',
    AddPaymentMethodClicked = 'add_payment_method_clicked',
    AmountEntry = 'amount_entry',
    BeginBitpayPayment = 'begin_bitpay_payment',
    BuyError = 'buy_error',
    BuyWineClicked = 'buy_wine_clicked',
    BadgeLoadMore = 'badge_load_more',
    Calendly = 'calendly',
    CalendlyEventScheduled = 'calendly_event_scheduled',
    CancelBuy = 'cancel_buy',
    CancelOrder = 'cancel_order',
    CancelSell = 'cancel_sell',
    ClickBuy = 'click_buy',
    ClickExistingPayment = 'click_existing_payment',
    ClickGetStarted = 'click_get_started',
    ClickMobileAppDownload = 'click_mobile_app_download',
    ClickNewPayment = 'click_new_payment',
    CollectionPdf = 'collection_pdf',
    CompareAccount = 'compare_account',
    ReferralPopup = 'referral_popup',
    CompleteBuy = 'complete_buy',
    CompletedBitpayPayment = 'completed_bitpay_payment',
    ConfirmBuy = 'confirm_buy',
    ConfirmBuyOrder = 'confirm_buy_order',
    ConfirmSell = 'confirm_sell',
    ConfirmSellOrder = 'confirm_sell_order',
    CryptocurrencySelectContinue = 'cryptocurrency_select_continue',
    DepositAdded = 'deposit_added',
    DepositAmountAdded = 'deposit_amount_added',
    DepositInitiated = 'deposit_initiated',
    DownloadMailACheck = 'download_mail_a_check',
    DownloadWirePDF = 'download_wire_pdf',
    HasDeposited = 'has_deposited',
    IdentifyUser = 'identify_user',
    NewPaymentMethodAdded = 'new_payment_method_added',
    PaymentMethodLinked = 'payment_method_linked',
    PreviewDepositClicked = 'preview_deposit_clicked',
    ReviewBuyOrder = 'review_buy_order',
    ReviewSellOrder = 'review_sell_order',
    SelectAccount = 'select_account',
    SelectBuy = 'select_buy',
    SelectBuyMore = 'select_buy_more',
    SelectCollectionWine = 'select_collection_wine',
    SelectComparePortfolio = 'select_compare_portfolio',
    SelectCreditCard = 'select_credit_card',
    SelectCryptocurrency = 'select_cryptocurrency',
    SelectFrequency = 'select_frequency',
    SelectManagedPortfolio = 'select_managed_portfolio',
    SelectMarketplaceWine = 'select_marketplace_wine',
    SelectMarket = 'select_market',
    SelectPathModalClickStartHere = 'select_path_modal_click_start_here',
    SelectPathModalClickX = 'select_path_modal_click_x',
    SelectPathModalWatchVideo = 'select_path_modal_watch_video',
    SelectPaymentMethod = 'select_payment_method',
    SelectSell = 'select_sell',
    SelectTrade = 'select_trade',
    SelectTrading = 'select_trading',
    SelectWhiskey = 'select_whiskey',
    SelectTradingPortfolio = 'select_trading_portfolio',
    SelectTradingPortfolioWine = 'view_trading_portfolio_wine',
    SelectWire = 'select_wire',
    SellOrderPosted = 'sell_order_posted',
    SetupAutoInvestClicked = 'setup_auto_invest_clicked',
    SetupManagedPortfolio = 'setup_managed',
    SetupTrading = 'setup_trading',
    SkipTradingTooltip = 'skip_trading_tooltip',
    TierOverview = 'tier_overview',
    ToggleSize = 'toggleSize',
    TooltipNext = 'tooltip_next',
    TooltipSkip = 'tooltip_skip',
    ViewCollection = 'view_collection',
    ViewCollections = 'view_collections',
    ViewAllCollections = 'view_all_collections',
    ViewOrders = 'view_orders',
    ViewPortfolioWine = 'view_portfolio_wine',
    ViewTradingPortfolio = 'view_trading_portfolio',
    ViewTradingActivity = 'view_activity',
    ViewWireTransferInstruction = 'viewed_wire_transfer_instruction',
    PhoneEntry = 'phone_entry',
    ReferralAction = 'referral_action',
    RegistrationComplete = 'registration_complete',
    UserRegistration = 'user_registration',
    ClickInviteLearnMore = 'click_invite_learn_more',
    ClickSellYourWineAccountSetting = 'click_sell_your_wine_account_setting',
    ViewListingsAccountSetting = 'view_liquidation_queue',
    RebalanceCalendy = 'rebalance_calendy',
    SignupInvestmentQ = 'signup_investment_q',
    QuizCompleted = 'quiz_completed',
    ClickAcknowledgement = 'click_acknowledgement',
    ClickNextDescribeGoal = 'click_next_describe_goal',
    ClickNextSelectWines = 'click_next_select_wines_to_sell',
    ClickNextConfirmAgreements = 'click_next_confirm_agreements',
    ClickNextWithdrawReinvest = 'click_next_withdraw_reinvest',
    ReinvestFunds = 'reinvest_funds',
    LiquidationContinue = 'liquidation_continue',
    LeaveLiquidation = 'leave_liquidation',
    LiquidationSurvey = 'liquidation_survey',
    LiquidationFeedback = 'liquidation_feedback',
    LiquidationNoFeedback = 'liquidation_no_feedback',
    LiquidationNewsletterOptIn = 'liquidation_newsletter_opt_in',
    LiquidationFlowNewsletterCheckbox = 'liquidation_flow_newsletter_checkbox',
    FlexibilityActivated = 'flexibility_activated',
    FlexibilityDeactivated = 'flexibility_deactivated',
    FlexibilityToggle = 'flexibility_toggle',
    GoogleSignUpClick = 'user_clicks_gmail_signup',
    AppleSignUpClick = 'user_clicks_apple_signup',
    EmailSignUpClick = 'user_clicks_email_signup',
    PortfolioReviewRequestCall = 'liquidation_portfolio_review_request_call',
    PortfolioReviewRequestEmail = 'liquidation_portfolio_review_request_email',
    OpenApp = 'open_app',
    ContinueWeb = 'continue_web',
    QuizQuestionEntry = 'quiz_question_entry',
    TierContinue = 'tier_continue',
    SelectTier = 'select_tier',
    MoreOptions = 'more_options',
    EditAmount = 'edit_amount',
    UpdateAmount = 'update_amount',
    BeginQuiz = 'begin_quiz',
    UserSignup = 'user_signup',
    GetStartedClick = 'get_started_click',
    SignupPasswordEntry = 'password_entry',
    SignupEmailEntry = 'email_entry',
    UserUpdated = 'user_updated',
    UtmCapture = 'utm_capture',
    SignupNameEntry = 'name_entry',
    DepositAcknowledgementConfirmed = 'deposit_acknowledgment_confirmed',
    DepositAcknowledgementStart = 'deposit_acknowledgment_start',
    ViewListings = 'view_listings',
    SelectBulkList = 'select_bulk_list',
    SelectCancelLiquidation = 'select_cancel_liquidation',
    SelectManuallyList = 'select_manually_list',
    SelectBulkListConfirm = 'select_bulk_list_confirm',
    SelectCancelLiquidationConfirm = 'select_cancel_liquidation_confirm',
    ListingErrorPrompt = 'listing_error_prompt',
    WhiskeyGuide = 'whiskey_guide',
    WhiskeyFaq = 'whiskey_faq',
    SelectWine = 'select_wine',
    ProfileRecommendation = 'profile_recommendation',
    UserSelection = 'user_selection',
    LiquidationFaq = 'liquidation_faq',
    SelectBadge = 'select_badge',
    PricingExplanation = 'pricing_explanation',
    SelectGetStarted = 'select_get_started',
    SelectPerformanceReport = 'select_performance_report',
    UserSelectLogin = 'user_select_login',
    UserLogin = 'user_login',
    SkipCall = 'skip_call',
    DepositConfirmation = 'deposit_confirmation',
    SelectPortfolio = 'select_portfolio',
    SelectManaged = 'select_managed',
    SelectActivity = 'select_activity',
    SelectDocuments = 'select_documents',
    SelectFaq = 'select_faq',
    SelectOverview = 'select_overview',
    VideoStart = 'video_start',
    VideoEnd = 'video_end',
    SelectWhiskeyLots = 'select_whiskey_lots',
    SelectAmericanWhiskey = 'select_american_whiskey',
    SelectUltraRare = 'select_ultra_rare',
    SelectReserve = 'select_reserve',
    AdjustQuantity = 'adjust_quantity',
    AnchorItem = 'anchor_item',
    PostDepositUpsellContinue = 'post_deposit_upsell_continue',
    PostDepositUpsellSkip = 'post_deposit_upsell_skip',
    ActiveRebalanceSettings = 'active_rebalance_settings',
    AutopayEnrollSelected = 'autopay_enroll_selected',
    AutopayAcknowledgementConfirmed = 'autopay_acknowledgement_confirmed',
    AutopayMethodUpdated = 'autopay_method_updated',
    SelectDeactiveAutopay = 'select_deactive_autopay',
    AutopayDeactivated = 'autopay_deactivated',
    SelectDynamicPricing = 'select_dynamic_pricing',
    ActivateDynamicPricing = 'activate_dynamic_pricing',
    DeactivateDynamicPricing = 'deactivate_dynamic_pricing',
    UpsellBuyNow = 'upsell_buy_now',
    UpsellCancel = 'upsell_cancel',
    UpsellPurchase = 'upsell_purchase',
    ReservationExpired = 'reservation_expired',
    SelectAutopay = 'select_autopay',
    WithdrawRequestInitial = 'withdraw_request_initial',
    WithdrawalRequestConfirmed = 'withdrawal_request_confirmed',
    DiscoverLoadMore = 'discover_load_more',
    SelectDiscoverItem = 'select_discover_item',
    AdjustAllocation = 'adjust_allocation'
}

export enum GoogleTagManager {
    AppointmentScheduled = 'appointment_scheduled',
    DepositStarted = 'deposit_started',
    EcommerceTransaction = 'ecommerce_transaction',
    IdentifyUser = 'identify_user',
    SelectFundSource = 'select_fund_source',
    RegistrationComplete = 'registration_complete'
}
type Count = `${number}` | `${number}${number | '0'}`;

export enum AddFundsInitiators {
    Tooltip = 'tooltip',
    UpgradeAccount = 'upgrade_account',
    PortfolioOverview = 'portfolio_overview',
    OverviewHero = 'overview_hero',
    Header = 'header',
    ManagedUpsell = 'managed_upsell'
}
/**
 * Event map of all analytics events.
 */
export type AnalyticsEventMap = {
    [AnalyticsEventNames.UtmCapture]: Record<string, any>;
    [AnalyticsEventNames.UserUpdated]: Record<string, any>;
    [AnalyticsEventNames.UserSignup]: {
        signup_method?: 'apple' | 'google' | 'email' | undefined;
        type?: 'wine' | 'whiskey';
    };
    [AnalyticsEventNames.GetStartedClick]: undefined;
    [AnalyticsEventNames.SignupPasswordEntry]: undefined;
    [AnalyticsEventNames.SignupEmailEntry]: undefined;
    [AnalyticsEventNames.SignupNameEntry]: undefined;
    [AnalyticsEventNames.SelectWhiskey]: undefined;
    [AnalyticsEventNames.BeginQuiz]: undefined;
    [AnalyticsEventNames.QuizQuestionEntry]: { order: number; question: string; answer: string; location?: string };
    [AnalyticsEventNames.TierContinue]: { tier: string };
    [AnalyticsEventNames.SelectTier]: { tier: string };
    [AnalyticsEventNames.MoreOptions]: undefined;
    [AnalyticsEventNames.EditAmount]: undefined;
    [AnalyticsEventNames.UpdateAmount]: { amount: number };
    [AnalyticsEventNames.AccountSettings]: undefined;
    [AnalyticsEventNames.ActiveToggle]: { state: OrderStatus };
    [AnalyticsEventNames.PhoneEntry]: { phoneNumber: string };
    [AnalyticsEventNames.SelectPaymentMethod]: { paymentMethod: string; type?: string };
    [GoogleTagManager.EcommerceTransaction]: undefined;
    [GoogleTagManager.AppointmentScheduled]: { user: string };
    [GoogleTagManager.IdentifyUser]: { user: string };
    [AnalyticsEventNames.AddFunds]: { initiator: AddFundsInitiators; page?: string; tier?: string; location?: string };
    [AnalyticsEventNames.AddPaymentMethodClicked]: undefined;
    [AnalyticsEventNames.AmountEntry]: undefined;
    [AnalyticsEventNames.BeginBitpayPayment]: undefined;
    [AnalyticsEventNames.BeginBitpayPayment]: undefined;
    [AnalyticsEventNames.BuyError]: { wine?: string; error?: string };
    [AnalyticsEventNames.BuyWineClicked]: undefined;
    [AnalyticsEventNames.Calendly]: { initiator?: string; progress?: string; component?: string; location?: string };
    [AnalyticsEventNames.CalendlyEventScheduled]: undefined;
    [AnalyticsEventNames.CancelBuy]: { wine?: string };
    [AnalyticsEventNames.CancelOrder]: { type: string; lwin16?: string };
    [AnalyticsEventNames.CancelSell]: { wine: string };
    [AnalyticsEventNames.ClickBuy]: { wine?: string };
    [AnalyticsEventNames.ClickExistingPayment]: undefined;
    [AnalyticsEventNames.ClickGetStarted]: undefined;
    [AnalyticsEventNames.ClickMobileAppDownload]: { type?: string };
    [AnalyticsEventNames.ClickNewPayment]: undefined;
    [AnalyticsEventNames.CollectionPdf]: { collection: string };
    [AnalyticsEventNames.CompareAccount]: undefined;
    [AnalyticsEventNames.ReferralPopup]: undefined;
    [AnalyticsEventNames.CompleteBuy]: {
        wine?: string;
        price: number;
        quantity: number;
        lwin16: string;
        collectionId?: string;
        portfolioID?: string;

        currency: string;
    };
    [AnalyticsEventNames.CompletedBitpayPayment]: undefined;
    [AnalyticsEventNames.CompletedBitpayPayment]: undefined;
    [AnalyticsEventNames.ConfirmBuy]: { price?: number; wine?: string; quantity: number };
    [AnalyticsEventNames.ConfirmBuyOrder]: { price: number; quantity: number };
    [AnalyticsEventNames.ConfirmSell]: undefined;
    [AnalyticsEventNames.ConfirmSellOrder]: { price: number; quantity: number };
    [AnalyticsEventNames.CryptocurrencySelectContinue]: { portfolio: PortfolioTypes };
    [AnalyticsEventNames.HasDeposited]: {
        has_deposited: boolean;
    };
    [AnalyticsEventNames.DepositAdded]: {
        balance_id: string;
        currency_code: string;
        customer_id: string;
        event_type: string;
        payment_id: string;
        transaction_amount: number;
        transaction_date: string;
        transaction_id: string;
        transaction_mode: string;
        transaction_type: string;
    };
    [AnalyticsEventNames.DepositAmountAdded]: { amount?: number; frequency?: string };
    [AnalyticsEventNames.DepositInitiated]: {
        email?: string;
        userID: string;
        paymentSource: string;
        value: number;
        depositAmount: number;
        frequency: string;
        depositFeesinDollars: number;
        destination: string;
        entity_type: string;
        currency: Currencies;
        records: [{ deposit_initiated: boolean; customer_id: string }];
    };
    [AnalyticsEventNames.RegistrationComplete]: {
        email?: string;
        userID: string;
    };
    [AnalyticsEventNames.UserRegistration]: undefined;
    [AnalyticsEventNames.DepositAcknowledgementConfirmed]: { type: string };
    [AnalyticsEventNames.DepositAcknowledgementStart]: { type: string };
    [AnalyticsEventNames.DownloadWirePDF]: { type: string; portfolio: PortfolioTypes };
    [AnalyticsEventNames.DownloadMailACheck]: undefined;
    [AnalyticsEventNames.NewPaymentMethodAdded]: { method?: string };
    [AnalyticsEventNames.PreviewDepositClicked]: undefined;
    [AnalyticsEventNames.ReviewBuyOrder]: undefined;
    [AnalyticsEventNames.ReviewSellOrder]: undefined;
    [AnalyticsEventNames.SelectAccount]: { account: string };
    [AnalyticsEventNames.SelectBuy]: { lwin: string; collection: boolean };
    [AnalyticsEventNames.SelectBuyMore]: undefined;
    [AnalyticsEventNames.SelectCollectionWine]: { page?: string; wine?: string };
    [AnalyticsEventNames.SelectComparePortfolio]: { page: string; header: boolean };
    [AnalyticsEventNames.SelectCreditCard]: undefined;
    [AnalyticsEventNames.SelectCreditCard]: undefined;
    [AnalyticsEventNames.SelectCryptocurrency]: undefined;
    [AnalyticsEventNames.SelectFrequency]: { value: 'one-time' | 'weekly' | 'monthly' };
    [AnalyticsEventNames.SelectManagedPortfolio]: { page: string; header: boolean };
    [AnalyticsEventNames.SelectMarketplaceWine]: { wine?: string };
    [AnalyticsEventNames.SelectMarket]: { page: string; header: boolean };
    [AnalyticsEventNames.SelectPathModalClickStartHere]: { portfolio: PortfolioTypes };
    [AnalyticsEventNames.SelectPathModalWatchVideo]: { portfolio: PortfolioTypes };
    [AnalyticsEventNames.SelectPathModalClickX]: undefined;
    [AnalyticsEventNames.SelectSell]: { page: string; wine: string };
    [AnalyticsEventNames.SelectTrade]: { page: string; header: boolean };
    [AnalyticsEventNames.SelectTradingPortfolioWine]: { page: string; wine: string };
    [AnalyticsEventNames.SelectTradingPortfolio]: undefined;
    [AnalyticsEventNames.SelectTrading]: { page: string; header: boolean };
    [AnalyticsEventNames.SelectWire]: undefined;
    [AnalyticsEventNames.SellOrderPosted]: { date: string; lwin16?: string };
    [AnalyticsEventNames.SetupAutoInvestClicked]: undefined;
    [AnalyticsEventNames.SetupManagedPortfolio]: undefined;
    [AnalyticsEventNames.SetupTrading]: undefined;
    [AnalyticsEventNames.SkipTradingTooltip]: undefined;
    [AnalyticsEventNames.TierOverview]: undefined;
    [AnalyticsEventNames.ToggleSize]: { wine: string };
    [AnalyticsEventNames.TooltipNext]: { stage: `tooltip${Count}` | string };
    [AnalyticsEventNames.TooltipSkip]: { stage: string };
    [AnalyticsEventNames.ViewCollection]: { page: string; collection: string };
    [AnalyticsEventNames.ViewCollections]: undefined;
    [AnalyticsEventNames.ViewAllCollections]: undefined;
    [AnalyticsEventNames.ViewOrders]: { page: string };
    [AnalyticsEventNames.ViewPortfolioWine]: { wine: string };
    [AnalyticsEventNames.ViewTradingPortfolio]: undefined;
    [AnalyticsEventNames.ViewWireTransferInstruction]: { type: string };
    [GoogleTagManager.EcommerceTransaction]: undefined;
    [AnalyticsEventNames.ReferralAction]: { action: string };
    [AnalyticsEventNames.ClickInviteLearnMore]: undefined;
    [AnalyticsEventNames.ClickSellYourWineAccountSetting]: undefined;
    [AnalyticsEventNames.ViewListingsAccountSetting]: undefined;
    [AnalyticsEventNames.RebalanceCalendy]: { component: string; progress: string; location: string };
    [AnalyticsEventNames.SignupInvestmentQ]: {
        investment_amount: number;
        investment_timeframe: string;
        investingStyle: string;
    };
    [AnalyticsEventNames.QuizCompleted]: { investment_amount: number };
    [AnalyticsEventNames.ClickAcknowledgement]: undefined;
    [AnalyticsEventNames.ClickNextDescribeGoal]: undefined;
    [AnalyticsEventNames.ClickNextSelectWines]: undefined;
    [AnalyticsEventNames.ClickNextConfirmAgreements]: undefined;
    [AnalyticsEventNames.ClickNextWithdrawReinvest]: undefined;
    [AnalyticsEventNames.ReinvestFunds]: undefined;
    [AnalyticsEventNames.LiquidationContinue]: { screen_name?: string };
    [AnalyticsEventNames.LeaveLiquidation]: undefined;
    [AnalyticsEventNames.LiquidationSurvey]: {
        feedback: string;
        responded: boolean;
        feedBackOptional: string;
        subscribe?: boolean;
    };
    [AnalyticsEventNames.LiquidationFeedback]: { selectedFeedBackOption: string };
    [AnalyticsEventNames.LiquidationNoFeedback]: undefined;
    [AnalyticsEventNames.LiquidationNewsletterOptIn]: { feedBackOptional: string };
    [AnalyticsEventNames.LiquidationFlowNewsletterCheckbox]: undefined;
    [AnalyticsEventNames.FlexibilityActivated]: { type: 'single' | 'bulk'; lwin: string[] | string };
    [AnalyticsEventNames.FlexibilityDeactivated]: { type: 'single' | 'bulk'; lwin: string[] | string };
    [AnalyticsEventNames.FlexibilityToggle]: undefined;
    [AnalyticsEventNames.ViewTradingActivity]: { type: string };
    [AnalyticsEventNames.GoogleSignUpClick]: undefined;
    [AnalyticsEventNames.AppleSignUpClick]: undefined;
    [AnalyticsEventNames.EmailSignUpClick]: undefined;
    [AnalyticsEventNames.PortfolioReviewRequestCall]: { screen_name: string };
    [AnalyticsEventNames.PortfolioReviewRequestEmail]: { screen_name: string };
    [AnalyticsEventNames.OpenApp]: { screen_name: string };
    [AnalyticsEventNames.ContinueWeb]: { screen_name: string };
    [AnalyticsEventNames.BadgeLoadMore]: undefined;
    [AnalyticsEventNames.AccountExistsError]: { email?: string };
    [AnalyticsEventNames.ViewListings]: undefined;
    [AnalyticsEventNames.SelectBulkList]: { location?: string };
    [AnalyticsEventNames.SelectCancelLiquidation]: undefined;
    [AnalyticsEventNames.SelectBulkListConfirm]: undefined;
    [AnalyticsEventNames.SelectCancelLiquidationConfirm]: undefined;
    [AnalyticsEventNames.SelectManuallyList]: undefined;
    [AnalyticsEventNames.ListingErrorPrompt]: undefined;
    [AnalyticsEventNames.WhiskeyGuide]: undefined;
    [AnalyticsEventNames.WhiskeyFaq]: undefined;
    [AnalyticsEventNames.SelectWine]: undefined;
    [AnalyticsEventNames.LiquidationFaq]: undefined;
    [AnalyticsEventNames.ProfileRecommendation]: {
        recommendation: string;
    };
    [AnalyticsEventNames.UserSelection]: {
        type: string;
        recommendation: string;
    };
    [AnalyticsEventNames.SelectBadge]: undefined;
    [AnalyticsEventNames.PricingExplanation]: undefined;
    [AnalyticsEventNames.SelectGetStarted]: {
        location: string;
    };
    [AnalyticsEventNames.SelectPerformanceReport]: {
        date: string;
    };
    [AnalyticsEventNames.UserSelectLogin]: undefined;
    [AnalyticsEventNames.UserLogin]: undefined;
    [AnalyticsEventNames.SkipCall]: undefined;
    [AnalyticsEventNames.DepositConfirmation]: undefined;
    [AnalyticsEventNames.SelectPortfolio]: {
        type: string;
    };
    [AnalyticsEventNames.SelectManaged]: undefined;
    [AnalyticsEventNames.SelectActivity]: {
        type: string;
    };
    [AnalyticsEventNames.SelectDocuments]: {
        type: string;
    };
    [AnalyticsEventNames.SelectFaq]: undefined;
    [AnalyticsEventNames.SelectOverview]: undefined;
    [AnalyticsEventNames.VideoStart]: {
        type: string;
    };
    [AnalyticsEventNames.VideoEnd]: {
        type: string;
    };
    [AnalyticsEventNames.SelectWhiskeyLots]: undefined;
    [AnalyticsEventNames.SelectAmericanWhiskey]: undefined;
    [AnalyticsEventNames.SelectUltraRare]: undefined;
    [AnalyticsEventNames.SelectReserve]: {
        product?: string;
        quantity?: number;
        location?: string;
    };
    [AnalyticsEventNames.AdjustQuantity]: {
        adjustment: string;
    };
    [AnalyticsEventNames.AnchorItem]: {
        item: string;
    };
    [AnalyticsEventNames.PostDepositUpsellContinue]: {
        type: string;
    };
    [AnalyticsEventNames.PostDepositUpsellSkip]: {
        type: string;
    };
    [AnalyticsEventNames.ActiveRebalanceSettings]: undefined;
    [AnalyticsEventNames.AutopayEnrollSelected]: undefined;
    [AnalyticsEventNames.AutopayAcknowledgementConfirmed]: undefined;
    [AnalyticsEventNames.AutopayMethodUpdated]: undefined;
    [AnalyticsEventNames.SelectDeactiveAutopay]: undefined;
    [AnalyticsEventNames.AutopayDeactivated]: undefined;
    [AnalyticsEventNames.SelectDynamicPricing]: undefined;
    [AnalyticsEventNames.ActivateDynamicPricing]: { type: 'single' | 'bulk'; lwin: string[] | string };
    [AnalyticsEventNames.DeactivateDynamicPricing]: { type: 'single' | 'bulk'; lwin: string[] | string };
    [AnalyticsEventNames.UpsellBuyNow]: {
        quantity: number;
        upsell: string;
        price: number;
    };
    [AnalyticsEventNames.UpsellCancel]: {
        quantity: number;
        upsell: string;
        price: number;
    };
    [AnalyticsEventNames.UpsellPurchase]: {
        quantity: number;
        upsell: string;
        price: number;
        paymentMethod: 'cash' | 'bank' | 'credit_card';
    };
    [AnalyticsEventNames.ReservationExpired]: undefined;
    [AnalyticsEventNames.SelectAutopay]: undefined;
    [AnalyticsEventNames.WithdrawRequestInitial]: undefined;
    [AnalyticsEventNames.WithdrawalRequestConfirmed]: {
        portfolio: 'trading' | 'wine' | 'whiskey' | string;
        amount: number;
    };
    [AnalyticsEventNames.DiscoverLoadMore]: undefined;
    [AnalyticsEventNames.SelectDiscoverItem]: {
        title: string;
        display_position: number;
        category?: string;
    };
    [AnalyticsEventNames.AdjustAllocation]: {
        setting: 'wine' | 'whiskey' | 'both' | string;
    };
};
